import React, { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react';
import { useSelector } from '../../store';
import { useCreateCheckoutSessionMutation } from '../../store/pomeriumApi';
import { useBooleanFlagValue } from '@openfeature/react-sdk';
import { buildCancelUrl } from './cancel';
import { useSearchParams } from 'react-router-dom';
import { buildSuccessUrl } from './success';
import { Backdrop, CircularProgress, Container, Stack } from '@mui/material';
import Page from '../../components/Page';
import TextField from '../../components/styled/TextField';
import SaveButton from '../../components/SaveButton';
import Header from '../../components/Header';
import { firebaseAuth } from '../../App';
import { useIdToken } from 'react-firebase-hooks/auth';

export function buildUpgradeUrl(returnUrl: string | null): string {
  return `${location.origin}/app/billing/upgrade${returnUrl ? `?returnUrl=${encodeURIComponent(returnUrl)}` : ''}`;
}

const Upgrade: FC = () => {
  const { organizationId } = useSelector((state) => state.current);
  const [createCheckoutSession, { isLoading }] =
    useCreateCheckoutSessionMutation();
  const [searchParams] = useSearchParams();
  const [user] = useIdToken(firebaseAuth);
  const [billingEmail, setBillingEmail] = useState(user?.email || '');
  const [organizationName, setOrganizationName] = useState('');

  const onChangeBillingEmail = (evt: ChangeEvent<HTMLInputElement>) => {
    setBillingEmail(evt.target.value);
  };

  const onChangeOrganizationName = (evt: ChangeEvent<HTMLInputElement>) => {
    setOrganizationName(evt.target.value);
  };

  const onUpgrade = (evt: MouseEvent) => {
    evt.preventDefault();

    (async () => {
      const result = await createCheckoutSession({
        organizationId,
        createCheckoutSessionRequest: {
          billingEmail,
          organizationName,
          cancelUrl: buildCancelUrl(searchParams.get('returnUrl')),
          successUrl: buildSuccessUrl(searchParams.get('returnUrl'))
        }
      });
      if ('data' in result && result.data.url) {
        location.href = result.data.url;
      }
    })();
  };

  return (
    <>
      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
      <Page title="Upgrade">
        <Container>
          <Header title="Upgrade" />
          <Stack gap={3}>
            <TextField
              label="Organization Name"
              value={organizationName}
              onChange={onChangeOrganizationName}
            />
            <TextField
              label="Billing Email"
              value={billingEmail}
              onChange={onChangeBillingEmail}
            />
            <SaveButton
              variant="contained"
              disabled={!billingEmail || !organizationName}
              onClick={onUpgrade}
            >
              Upgrade
            </SaveButton>
          </Stack>
        </Container>
      </Page>
    </>
  );
};

export default Upgrade;

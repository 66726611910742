import Typography from '@mui/material/Typography';
import { get, omit } from 'lodash';
import React, { FC } from 'react';
import { useSelector } from '../../../store';
import {
  GetDistributionTimeSeriesApiArg,
  useGetDistributionSumQuery
} from '../../../store/pomeriumApi';
import { Card, CardContent, Skeleton } from '@mui/material';
import {
  AllSeriesType,
  BarPlot,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  ResponsiveChartContainer
} from '@mui/x-charts';
import Alert from '@mui/material/Alert';
import {
  formatDurationMS,
  formatInteger,
  formatPercent
} from '../../../utils/format';

const RequestDurationPercent: FC = () => {
  const { organizationId, clusterId } = useSelector((state) => state.current);
  const { routeId, reportPeriod } = useSelector((state) => state.metrics);
  const now = new Date(Math.floor(Date.now() / 60000) * 60000);
  const start = new Date(now.getTime() - reportPeriod);
  let params: GetDistributionTimeSeriesApiArg = {
    organizationId,
    clusterId,
    startTime: start.toISOString(),
    endTime: now.toISOString(),
    routeId: routeId,
    metricId: 'upstream_request_time',
    percentile: 99
  };
  if (!routeId) {
    params = omit(params, ['routeId']);
  }

  const { data: authReqs } = useGetDistributionSumQuery(params, {
    skip: !organizationId || !clusterId || !reportPeriod
  });

  const counts: number[] = get(
    authReqs,
    '0.distributionValue.bucketCounts',
    []
  );
  const xAxis: number[] = get(
    authReqs,
    '0.distributionValue.explicitBucketBounds',
    []
  ).slice(0, counts.length);
  const total = get(authReqs, '0.distributionValue.count', 0);

  return (
    <Card
      sx={{
        width: '100%',
        m: 2
      }}
    >
      <CardContent sx={{ height: '40vh', m: 2 }}>
        <Typography variant={'h4'} color="text.primary" gutterBottom>
          Request Duration
        </Typography>
        {!authReqs && (
          <Skeleton width="100%" height="100%" sx={{ transform: 'unset' }} />
        )}
        {authReqs && !counts?.length && (
          <Alert severity="info">
            No data to display. Data is updated hourly.
          </Alert>
        )}
        {!!counts?.length && (
          <ResponsiveChartContainer
            yAxis={[
              {
                id: 'request-duration-percent-y',
                label: 'Number of Requests'
              }
            ]}
            xAxis={[
              {
                id: 'request-duration-percent-x',
                scaleType: 'band',
                data: xAxis,
                valueFormatter: (value: number) => formatDurationMS(value)
              }
            ]}
            series={[
              {
                id: 'request-duration-percent',
                type: 'bar',
                data: counts,
                valueFormatter: (value: number) => {
                  if (value === null) {
                    return '';
                  }
                  return (
                    formatInteger(value) +
                    ' requests (' +
                    formatPercent(value, total) +
                    ')'
                  );
                }
              } as AllSeriesType<'bar'>
            ]}
          >
            <ChartsTooltip trigger="axis" />
            <BarPlot />
            <ChartsXAxis
              label="Duration in Milliseconds"
              position="bottom"
              axisId="request-duration-percent-x"
            />
            <ChartsYAxis axisId={'request-duration-percent-y'} />
          </ResponsiveChartContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default RequestDurationPercent;

import React, { FC } from 'react';
import Page from '../../components/Page';
import Container from '@mui/material/Container';
import { useSelector } from '../../store';
import { Card, CardContent, Stack } from '@mui/material';
import {
  AllSeriesType,
  AxisConfig,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  ResponsiveChartContainer
} from '@mui/x-charts';
import Typography from '@mui/material/Typography';
import Header from '../../components/Header';
import { useGetTimeSeriesQuery } from '../../store/pomeriumApi';
import { getClusterUsageInterval } from './interval';
import Alert from '@mui/material/Alert';
import { ChartsXAxisProps } from '@mui/x-charts/models/axis';
import LinearProgress from '@mui/material/LinearProgress';
import { get } from 'lodash';

const Usage: FC = () => {
  const { clusterId, organizationId } = useSelector((state) => state.current);

  const now = new Date();
  const dauInterval = getClusterUsageInterval('dau', now);
  const mauInterval = getClusterUsageInterval('mau', now);

  const { data: dau, isLoading: dauLoading } = useGetTimeSeriesQuery(
    {
      organizationId,
      clusterId,
      metricId: 'dau',
      startTime: dauInterval.start.toISOString(),
      endTime: dauInterval.end.toISOString()
    },
    { skip: !organizationId || !clusterId }
  );

  const { data: mau, isLoading: mauLoading } = useGetTimeSeriesQuery(
    {
      organizationId,
      clusterId,
      metricId: 'mau',
      startTime: mauInterval.start.toISOString(),
      endTime: mauInterval.end.toISOString()
    },
    { skip: !organizationId || !clusterId }
  );

  const noData = !mau?.length && !dau?.length;

  if (!dau || !mau) {
    return <LinearProgress />;
  }

  const mauData = mau?.map((ts) => {
    return {
      series: {
        id: 'mauData',
        label: 'Monthly Active Users',
        type: 'line',
        data: ts.points.map((p) => p.value)
      } as AllSeriesType<'line'>,
      xAxis: {
        id: 'mauX',
        data: ts.points.map((p) => new Date(p.timestamp)),
        scaleType: 'utc'
      } as AxisConfig<'utc', any, ChartsXAxisProps>
    };
  });

  const dauData = dau?.map((ts) => {
    return {
      series: {
        id: 'dauData',
        label: 'Daily Active Users',
        type: 'line',
        data: ts.points.map((p) => p.value)
      } as AllSeriesType<'line'>,
      xAxis: {
        id: 'dauX',
        data: ts.points.map((p) => new Date(p.timestamp)),
        scaleType: 'utc'
      } as AxisConfig<'utc', any, ChartsXAxisProps>
    };
  });

  return (
    <Page title="Usage">
      <Header breadcrumbs={true} title="Usage" />
      <Container maxWidth={false}>
        <Stack>
          {noData && (
            <Alert severity="info">
              No data to display. Data is updated hourly.
            </Alert>
          )}
          {!dauLoading && !!dauData?.length && (
            <Card sx={{ mb: 5 }}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 24 }}
                  color="text.primary"
                  gutterBottom
                >
                  Daily Active Users
                </Typography>
                <Stack
                  direction="row"
                  spacing={{ xs: 2, sm: 4, md: 6 }}
                  sx={{ height: '40vh' }}
                >
                  <ResponsiveChartContainer
                    xAxis={dauData.map((d) => d.xAxis)}
                    series={dauData.map((d) => d.series)}
                  >
                    <LinePlot />
                    <ChartsXAxis axisId={'dauX'} />
                    <ChartsYAxis />
                    <ChartsTooltip trigger="axis" />
                  </ResponsiveChartContainer>
                  <Stack alignItems="center" sx={{ minWidth: 250 }}>
                    <Typography sx={{ fontSize: 32 }} color="primary">
                      {get(dauData, '0.series.data', [0]).slice(-1)}
                    </Typography>
                    <Typography sx={{ fontSize: 18 }} color="primary">
                      Active Users
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          )}

          {!mauLoading && !!mauData?.length && (
            <Card>
              <CardContent>
                <Typography
                  sx={{ fontSize: 24 }}
                  color="text.primary"
                  gutterBottom
                >
                  Monthly Active Users
                </Typography>
                <Stack
                  direction="row"
                  spacing={{ xs: 2, sm: 4, md: 6 }}
                  sx={{ height: '40vh' }}
                >
                  <ResponsiveChartContainer
                    xAxis={mauData.map((d) => d.xAxis)}
                    series={mauData.map((d) => d.series)}
                  >
                    <LinePlot />
                    <ChartsXAxis axisId={'mauX'} />
                    <ChartsYAxis />
                    <ChartsTooltip trigger="axis" />
                  </ResponsiveChartContainer>
                  <Stack alignItems="center" sx={{ minWidth: 250 }}>
                    <Typography sx={{ fontSize: 32 }} color="primary">
                      {get(mauData, '0.series.data', [0]).slice(-1)}
                    </Typography>
                    <Typography sx={{ fontSize: 18 }} color="primary">
                      Active Users
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          )}
        </Stack>
      </Container>
    </Page>
  );
};

export default Usage;

import { DurationFormat } from '@formatjs/intl-durationformat/lib/src/core';
import { DurationFormatOptions } from '@formatjs/intl-durationformat/lib/src/types';

const durationFormatOptions: DurationFormatOptions = {
  round: true
};
const durationFormat =
  'DurationFormat' in (Intl as any)
    ? new (Intl as any).DurationFormat(undefined, durationFormatOptions)
    : new DurationFormat(undefined, durationFormatOptions);

export function formatDurationMS(durationInMilliseconds: number): string {
  if (durationInMilliseconds === null) {
    return '';
  }
  return durationFormat.format({
    milliseconds: Math.round(durationInMilliseconds)
  });
}

const integerFormat = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 0
});
export function formatInteger(integer: number): string {
  return integerFormat.format(integer);
}

const percentFormat = new Intl.NumberFormat(undefined, {
  style: 'percent',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
});
export function formatPercent(value: number, total: number): string {
  return percentFormat.format(value && total ? value / total : 0);
}

import React, { FC } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';

const NewBadge: FC = () => {
  const theme = useTheme();
  return (
    <Typography
      sx={{
        display: 'inline-block',
        padding: '2px 4px',
        fontWeight: 'bold',
        fontSize: '0.75rem',
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main
      }}
    >
      NEW
    </Typography>
  );
};
export default NewBadge;

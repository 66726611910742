import { SnackbarKey, useSnackbar } from 'notistack';
import { useEffect } from 'react';

import { removeNotification } from '../slices/notifications';
import { useDispatch, useSelector } from '../store';

const useNotifier = (): void => {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    notifications.forEach(({ message, options = {} }) => {
      const snackKey: SnackbarKey = enqueueSnackbar(message, {
        ...options,
        onClose: (event, reason, key) => {
          dispatch(removeNotification(key));
        },
        onExited: (event, key) => {
          dispatch(removeNotification(key));
        },
        SnackbarProps: {
          onClick: () => closeSnackbar(snackKey)
        }
      });
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};

export default useNotifier;

import Typography from '@mui/material/Typography';
import { omit } from 'lodash';
import React, { FC } from 'react';
import { useSelector } from '../../../store';
import {
  GetTimeSeriesApiArg,
  useGetTimeSeriesQuery
} from '../../../store/pomeriumApi';
import { Card, CardContent, Skeleton } from '@mui/material';
import Alert from '@mui/material/Alert';
import {
  AllSeriesType,
  AxisConfig,
  ChartsGrid,
  ChartsLegend,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  ResponsiveChartContainer
} from '@mui/x-charts';
import { ChartsXAxisProps } from '@mui/x-charts/models/axis';

const RequestDuration: FC = () => {
  const { organizationId, clusterId } = useSelector((state) => state.current);
  const { routeId, reportPeriod } = useSelector((state) => state.metrics);
  const now = new Date(Math.floor(Date.now() / 60000) * 60000);
  const start = new Date(now.getTime() - reportPeriod);

  let params: GetTimeSeriesApiArg = {
    organizationId,
    clusterId,
    startTime: start.toISOString(),
    endTime: now.toISOString(),
    routeId: routeId,
    metricId: 'upstream_requests'
  };
  if (!routeId) {
    params = omit(params, ['routeId']);
  }

  const { data: requestPerSecond } = useGetTimeSeriesQuery(params, {
    skip: !organizationId || !clusterId || !reportPeriod
  });

  const transformed = requestPerSecond?.map((ts) => ({
    series: {
      id: ts.labels.response_code_class + 'requests-per-second',
      label: ts.labels.response_code_class + '00s',
      type: 'line',
      data: ts.points.map((p) => p.value)
    } as AllSeriesType<'line'>,
    xAxis: {
      id: 'requests-per-second-x',
      data: ts.points.map((p) => new Date(p.timestamp)),
      scaleType: 'utc',
      min: start,
      max: now
    } as AxisConfig<'utc', any, ChartsXAxisProps>
  }));

  return (
    <Card
      sx={{
        width: '100%',
        m: 2
      }}
    >
      <CardContent sx={{ height: '40vh', m: 2 }}>
        <Typography variant={'h4'} color="text.primary" gutterBottom>
          Requests Per Second
        </Typography>
        {!requestPerSecond && (
          <Skeleton width="100%" height="100%" sx={{ transform: 'unset' }} />
        )}
        {requestPerSecond && !requestPerSecond?.length && (
          <Alert severity="info">
            No data to display. Data is updated hourly.
          </Alert>
        )}
        {transformed && !!requestPerSecond?.length && (
          <ResponsiveChartContainer
            xAxis={transformed.map((d) => d.xAxis)}
            series={transformed.map((d) => d.series)}
          >
            <ChartsLegend direction="row" />
            <LinePlot />
            <ChartsXAxis axisId={'requests-per-second-x'} />
            <ChartsYAxis />
            <ChartsGrid horizontal />
            <ChartsTooltip trigger="axis" />
          </ResponsiveChartContainer>
        )}
      </CardContent>
    </Card>
  );
};

export default RequestDuration;
